<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Terminate Journey</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleTerminateJourney" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Journey</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.journeys }">
                                    <Field autocomplete="off" name="journeys" v-model="form.journey_ids" rules="required">
                                        <multiselect
                                            v-model="form.journey_ids"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="journeys"
                                            placeholder="Select Journey"
                                            mode="tags"
                                        >
                                      </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="journeys" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.journey_ids.length">
                            <schedule-component :schedule-data="form" title="terminate journey" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Terminating' : 'Terminate' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'TerminateJourney',

        data () {
            return {
                form: {
                    contacts: '',
                    journey_ids: [],
                    action: '/contacts/terminate-journey',
                    type: 'leads',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.journeys.length == 0) {
                    vm.getAllJourneys({ select: 'id,name,action,action_title,action_type' });
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                journeys: state => state.journeyModule.allJourneys,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllJourneys: 'journeyModule/getAllJourneys',
                terminateJourney: 'journeyModule/terminateJourney',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contacts: '',
                    journey_ids: [],
                    action: '/contacts/terminate-journey',
                    type: 'leads',
                };
            },

            handleTerminateJourney (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.terminateJourney(vm.form);
            },
        },
    }
</script>
